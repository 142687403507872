import React from 'react';
import { graphql } from 'gatsby';
import { Col, Heading, Row, Text } from '@fast-ai/ui-components';

import MdxPage from '../layout/MdxPage';
import { Contacts, Primary, Section } from '../ui';

const CareersPage = ({ data: { mdx }, ...rest }) => {
	const after = (
		<Section variant="stripesSecondary">
			<Row sx={{ flexWrap: 'wrap' }}>
				<Col span={12}>
					<Heading id="contacts" sx={{ pt: [0], mb: [0, 0, 0] }}>
						Are you <Primary>interested</Primary>?<br />
						Take the <Primary>first</Primary> step!
					</Heading>

					<Text variant="subHeading2">
						Send us your CV or LinkedIn link and write a few lines about
						yourself.
					</Text>
					<Text>
						We&apos;ll go over everything, make a call and agree on the next
						steps.
					</Text>
				</Col>
			</Row>
			<Contacts />
		</Section>
	);

	return <MdxPage after={after} mdx={mdx} {...rest} />;
};
export default CareersPage;

export const pageQuery = graphql`
	query MdxCareersPageQuery($id: String!) {
		mdx(id: { eq: $id }) {
			frontmatter {
				title
				description
			}
			id
			body
			tableOfContents
		}
	}
`;
